.Login { 
    color:black;
}
.loggedinmenu {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #000;
    gap: 2rem;
    height: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
