.App {
  /* text-align: center; */
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.footerStyle {
  text-align: center;
  color: #fff;
  background-color: #000;  
  font-family: 'IBM Plex Mono', sans-serif;
};

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.appButtonPrimary{
  margin-bottom: 1rem;
    background-color: #000;
    color: #fff;
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    /* border: 0.16rem solid #000; */
    padding: 1rem;
    height: 3rem;
    border-radius: 1.5rem;
    max-width: 100%;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.ibm-plex-mono-thin {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-mono-extralight {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-mono-light {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-mono-regular {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-mono-medium {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-mono-semibold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-mono-bold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-mono-thin-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-mono-extralight-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-mono-light-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-mono-regular-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-mono-medium-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-mono-semibold-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-mono-bold-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: italic;
}
