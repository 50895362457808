.SnippetCard {}
.cardtitle{ 
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.6rem;
    text-transform: capitalize;
    font-style: normal;
    color: #313131;
}
.snippetcardholder{
    text-align: left;
    font-family: "IBM Plex Sans", sans-serif;
}
.snippetsubtitlegrey{
    font-family: "IBM Plex Mono", monospace;
    font-weight:400;
    text-transform: uppercase;
    color:#6c6a6a;
    font-size: 0.65rem;
    padding: 3px;
}
.detailssubtitle{
    display: flex;
    flex-direction: row;
    font-size: 0.65rem;
    color:#616161;
    gap: 1rem;
    padding: 0.15rem;
}
.subtitle {
    font-size: .85rem;
    font-family: "IBM Plex Sans", sans-serif;
    text-indent: 0.2rem;
    font-weight: 400;
    font-style: italic;
    color: #352f36;
    padding-top: 0.45rem;
   
}
 
.cardmenu{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 1rem 0 1rem 0rem;
    justify-content: center;
}
.snippetPreview{
    font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-style: italic;
   padding: 2rem;
   border-radius: 0.5rem; 
}

.iconStyle {
    font-size: 1.3rem;
    color: #828282;
  }
 
